<template>
  <v-container v-if="!flights || flights.length === 0">
    <message-card class="pa-12" v-if="division.hydrated">
      <h2>The pools have not been filled yet.</h2>
    </message-card>
    <message-card class="pa-12" v-else>
      <v-progress-circular indeterminate color="color3"></v-progress-circular>
    </message-card>
  </v-container>

  <div v-else>
    <flight-card v-for="(flight) in flights" :key="`flight_${flight.id}`" :flight="flight"></flight-card>
  </div>
</template>

<script>
import FlightCard from '@/components/Tournament/Pools/FlightCard.vue'

export default {
  data () {
    return {
    }
  },
  computed: {
    flights () {
      if (!this.day) return null
      return this.day.flights
    },
    day () {
      if (!this.division) return null
      return this.division.days.find(f => f.id === this.dayId)
    },
    dayId () {
      return +this.$route.params.dayId
    },
    division () {
      if (this.divisionId) return this.tournament.divisions.find(f => f.id === +this.divisionId)
      return null
    },
    divisionId () {
      return +this.$route.params.divisionId
    },
    tournament () {
      return this.$store.getters.tournament
    }
  },
  methods: {
  },
  components: {
    'flight-card': FlightCard
  }
}
</script>

<style scoped>

</style>
