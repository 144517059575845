export default {
  computed: {
    roundJProps () {
      const roundId = this.$route.name === 'league-play' ? this.$store.getters.getRoundIdByI(+this.$route.query.round) : +this.$route.params.dayId
      return this.$store.getters.getRoundJProps(roundId)
    },
    hideTimes () {
      const r = this.$store.getters.getRound(+this.$route.params.dayId)
      return !!(r && r.hideTimes)
    },
    hideSeeds () {
      return !!((this.roundJProps && this.roundJProps.noSeeds) || this.$store.getters.getDivisionJProp(+this.$route.params.divisionId, 'hideSeed'))
    },
    hideRefs () {
      return this.roundJProps && this.roundJProps.noRefs
    }
  }
}
